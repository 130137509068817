<template>
  <div class="video">
    <h>
      <span class="name">
        视频
      </span>
    </h>
    <div class="content">
      <cube-scroll ref="scroll" :options="{ click: true, bounce: false }">
        <cube-slide ref="slide" :data="data.bannerVideoColumnList">
          <cube-slide-item
            v-for="item in data.bannerVideoColumnList"
            :key="item.id"
          >
            <a :href="toPlay(item)">
              <img :src="resetUrl(item.horizontalImage)" />
            </a>
          </cube-slide-item>
        </cube-slide>
        <section class="category">
          <div v-show="data.newlyVideoColumnList.length">
            <h2>
              <b class="name">
                最新视频
              </b>
            </h2>
            <preview :list="data.newlyVideoColumnList" />
          </div>
          <div v-show="data.paymentVideoColumnList.length">
            <h2>
              <b class="name">
                金币视频
              </b>
              <router-link to="/more?roleStatus=3" tag="span" class="more">
                <i>更多</i>
                <img src="./img/more.png" width="11" />
              </router-link>
            </h2>
            <preview :list="data.paymentVideoColumnList" />
          </div>
          <div v-show="data.memberVideoColumnList.length">
            <h2>
              <b class="name">
                会员专属视频
              </b>
              <router-link to="/more?roleStatus=2" tag="span" class="more">
                <i>更多</i>
                <img src="./img/more.png" width="11" />
              </router-link>
            </h2>
            <preview :list="data.memberVideoColumnList" />
          </div>
          <div v-show="data.noPayVideoColumnList.length">
            <h2>
              <b class="name">
                免费视频
              </b>
              <router-link to="/more?roleStatus=1" tag="span" class="more">
                <i>更多</i>
                <img src="./img/more.png" width="11" />
              </router-link>
            </h2>
            <preview :list="data.noPayVideoColumnList" />
          </div>
          <div v-show="data.competitionVideoColumnList.length">
            <h2>
              <b class="name">
                比赛集锦
              </b>
              <router-link to="/more?lableType=2" tag="span" class="more">
                <i>更多</i>
                <img src="./img/more.png" width="11" />
              </router-link>
            </h2>
            <preview :list="data.competitionVideoColumnList" />
          </div>
          <div v-show="data.conventionVideoColumnList.length">
            <h2>
              <b class="name">
                常规桌
              </b>
              <router-link to="/more?lableType=1" tag="span" class="more">
                <i>更多</i>
                <img src="./img/more.png" width="11" />
              </router-link>
            </h2>
            <preview :list="data.conventionVideoColumnList" />
          </div>
        </section>
      </cube-scroll>
    </div>
    <f label="视频" />
    <cube-popup ref="loadingPopup" type="loading-popup">
      <cube-loading :size="28" />
    </cube-popup>
  </div>
</template>
<script>
import h from "@/components/base/header";
import f from "@/components/base/footer";
import preview from "@/components/video/preview";
import { getVideoData } from "@/api/video";
import { mapState } from "vuex";
export default {
  components: {
    h,
    f,
    preview
  },
  data() {
    return {
      data: {
        bannerVideoColumnList: [],
        newlyVideoColumnList: [],
        paymentVideoColumnList: [],
        memberVideoColumnList: [],
        noPayVideoColumnList: [],
        competitionVideoColumnList: [],
        conventionVideoColumnList: []
      }
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  created() {
    this._getVideoData();
  },
  methods: {
    loadingShow() {
      this.$nextTick(() => {
        this.$refs.loadingPopup.show();
      });
    },
    loadingHide() {
      this.$refs.loadingPopup.hide();
    },
    _getVideoData() {
      this.loadingShow();
      getVideoData()
        .then(res => {
          this.data = res.object;
        })
        .finally(() => {
          this.loadingHide();
        });
    },
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    },
    toPlay(item) {
      if (!this.token) {
        return "/login";
      }
      let url = "";
      if (item.videoType == 0 || item.videoType == 3) {
        url = `/video/${item.videoId}?type=${item.videoType}`;
      } else if (item.videoType == 1) {
        url = `/video/${item.categoryId}?type=${item.videoType}&number=${item.videoId}`;
      } else if (item.videoType == 2) {
        url =
          item.videoType.number <= 0
            ? `/search/more?id=${item.categoryId}`
            : `/video/${item.categoryId}?type=${item.videoType}`;
      }
      if (!item.videoType && item.isPay == 1) {
        url = `/video/${item.videoId}?type=3`;
      }
      return url;
    }
  }
};
</script>
<style lang="stylus" scoped>
.video
  position relative
  // height calc(100vh - 53px)
  // padding 0 0 53px
  .content
    position fixed
    top 36px
    right 0
    bottom 53px
    left 0
    // height calc(100vh - 36px - 53px)
    // height 400px
    .cube-slide
      .cube-slide-item
        a
          img
            height auto
    .category
      h2
        position relative
        margin 14px 17px 10px 10px
        .name
          display inline-block
          font-size 14px
          font-weight bold
          color #000
          &:before
            position relative
            top 2px
            display inline-block
            content ""
            width 3px
            height 14px
            background #1E88E4
            margin-right 7px
        .more
          position absolute
          top 50%
          right 0
          transform translateY(-50%)
          font-size 0
          i
            display inline-block
            vertical-align middle
            margin-right 2px
            font-style normal
            font-size 10px
            color #6B6B6B
          img
            display inline-block
            vertical-align middle
</style>
